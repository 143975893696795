.order-placed-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F9F9F9;
  }
  
  .order-placed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
  }
  
  .order-placed h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .order-placed p {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .order-summary {
    margin-top: 32px;
    text-align: center;
  }
  
  .order-summary h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .order-summary ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .order-summary li {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .order-summary p {
    font-size: 20px;
    font-weight: bold;
    margin-top: 16px;
  }
  
  .continue-shopping-btn {
    background-color: #1C1C1C;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 24px;
    margin-top: 32px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .continue-shopping-btn:hover {
    background-color: transparent;
    color: black;
    border: 2px solid black;
  }
  