.total-content {
  max-width:100%;
  margin: 0 auto;
  padding: 30px;
  font-family: sans-serif;
  text-align: center;
  background-color: rgb(250, 250, 239);
}

.search-box {
  width: 100%;
  max-width: 400px;
  padding: 10px 20px;
  margin-bottom: 30px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 0;
  margin: 0;
}

.card {
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  padding: 20px;
  max-height: 450px; 
  outline: 2px solid violet;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
}

.card h3 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card p {
  margin: 0;
  font-size: 16px;
  margin-bottom: 10px;
  white-space: nowrap; /* prevent the text from wrapping */
  overflow: hidden; /* hide any overflowing text */
  text-overflow: ellipsis; /* add ellipsis at the end of the line */
}


.card button {
  background-color: violet;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card button:hover {
  background-color: rgb(241, 70, 241);
}
.size-box
{
  padding: 15px;
  margin-right: 10px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
}