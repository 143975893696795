.checkout-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .checkout-form__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkout-form__header h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .checkout-form__section {
    margin-bottom: 20px;
  }
  
  .checkout-form__section h2 {
    font-size: 20px;
    margin: 0 0 10px;
  }
  
  .checkout-form__section label {
    display: block;
    margin-bottom: 10px;
  }
  
  .checkout-form__section input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  .checkout-form__section__row {
    display: flex;
    justify-content: space-between;
  }
  
  .checkout-form__section__row label {
    flex-basis: calc(33.33% - 10px);
  }
  
  .checkout-form__section__row input[type="radio"] {
    margin-right: 5px;
  }
  
  .checkout-form__submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0073bb;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
  }
  
  .checkout-form__submit-btn:hover {
    background-color: #005c8a;
  }
  